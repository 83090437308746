export const setCallHistory = (state, payload) => {
  state.callHistory.data = payload.data
  state.callHistory.meta.totalItems = payload.totalPages
}

export const setLoading = (state, isLoading) => {
  state.loading = isLoading
}

export const prependCallToHistory = (state, newCall) => {
  state.callHistory.data.pop()
  state.callHistory.data.unshift(newCall)
  state.callHistory.meta.totalItems += 1
}
