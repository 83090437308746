import LogEventEnum from '@/plugins/i3-logger/enums/i3-logger.enum'
import moment from 'moment/moment'
import EmergencyCallDataParser from '@/modules/api/csp/parsers/emergency-call-data.parser'
import ADREnum from '@/plugins/i3-logger/enums/adr-mapper.enum'

export default function helpers () {
  return {
    callType (request) {
      if (Array.isArray(request)) {
        const incidentID = request.some(item => item?.raw?.includes('purpose=nena-IncidentId'))
        return incidentID ? 'Emergency' : 'nonEmergency'
      } else {
        return 'nonEmergency'
      }
    },
    getNenaUid (callInfo, key) {
      if (!callInfo) return null
      for (const item of callInfo) {
        const match = `urn:nena:uid:${key}:`
        if (item && item?.raw.includes(match)) {
          const uid = item?.raw.match(/<(.*)>/)
          return uid ? uid[1] : null
        }
      }
      return null
    },
    getIpAddressAndPort (session, logEventName, subEventName, byVal) {
      const ADRUrls = new EmergencyCallDataParser().parseAdr(session?._request?.headers?.['Call-Info'])
      const geolocationURL = new EmergencyCallDataParser().parseGeoLocation(session?._request?.headers?.Geolocation)
      switch (logEventName) {
        case LogEventEnum.CallStartLogEvent:
          return this.extractIpAndPort(session?._request?.headers?.Contact?.[0]?.raw)
        case LogEventEnum.CallEndLogEvent:
          return this.extractIpAndPort(session?._request?.headers?.Contact?.[0]?.raw)
        case LogEventEnum.LocationQueryLogEvent:
          return new URL(geolocationURL?.location_url)?.hostname
        case LogEventEnum.LocationResponseLogEvent:
          if (byVal) {
            return this.extractIpAndPort(session?._request?.headers?.Contact?.[0]?.raw)
          } else {
            return new URL(geolocationURL?.location_url)?.hostname
          }
        case LogEventEnum.AdditionalDataQueryLogEvent:
          return new URL(ADRUrls[subEventName])?.hostname
        case LogEventEnum.AdditionalDataResponseLogEvent:
          if (byVal) {
            return this.extractIpAndPort(session?._request?.headers?.Contact?.[0]?.raw)
          } else {
            return new URL(ADRUrls[ADREnum[subEventName]])?.hostname
          }
        default:
          return this.extractIpAndPort(session?._request?.headers?.Contact?.[0]?.raw)
      }
    },
    extractIpAndPort (str) {
      // Regular expression pattern for matching an IPv6 address and port number
      const pattern = /\[([0-9a-fA-F:]+)\]:(\d+)/

      // Executing the regular expression on the input string
      const matches = pattern.exec(str)

      if (matches && matches.length === 3) {
      // Extracting the IPv6 address and port number
        const ipAddress = matches[1]
        const port = matches[2]

        return `${ipAddress}:${port}`
      } else {
        return ''
      }
    },
    getCorrectTime (session, logEventName) {
      switch (logEventName) {
        case LogEventEnum.CallStartLogEvent:
          return this.getTimestamp(session?._start_time)
        case LogEventEnum.CallEndLogEvent:
          return this.getTimestamp(session?._end_time)
        case LogEventEnum.LocationQueryLogEvent:
          return this.generateTimestamp()
        case LogEventEnum.LocationResponseLogEvent:
          return this.generateTimestamp()
        case LogEventEnum.AdditionalDataQueryLogEvent:
          return this.generateTimestamp()
        case LogEventEnum.AdditionalDataResponseLogEvent:
          return this.generateTimestamp()
        default:
          return this.getTimestamp(session?._start_time)
      }
    },
    getTimestamp (time) {
      const timezoneOffset = moment().format('Z')
      return moment(time).format(`YYYY-MM-DDTHH:mm:ss.SS${timezoneOffset}`)
    },
    generateTimestamp () {
      const timezoneOffset = moment().format('Z')
      return moment().format(`YYYY-MM-DDTHH:mm:ss.SS${timezoneOffset}`)
    },
    mapADR () {
      return [
        {
          key: 'comment_url',
          value: 'Comment'
        },
        {
          key: 'subscriber_info_url',
          value: 'SubscriberInfo'
        },
        {
          key: 'service_info_url',
          value: 'ServiceInfo'
        },
        {
          key: 'device_info_url',
          value: 'DeviceInfo'
        },
        {
          key: 'provider_info_url',
          value: 'ProviderInfo'
        }
      ]
    }
  }
}
