
export default function callHistoryApiModule (instance) {
  /**
   * View swagger documentation:
   * http://localhost:3000/docs#/CDRS/CdrsController_findAllPaginated
   *  **/
  return {

    /**
     * Get list of call history.
     * @param data
     * @returns {Promise<*>}
     */
    getCallsHistory: async (data) => {
      const resource = '/cdrs'
      return await instance.get(resource, {
        params: data
      })
    },

    /**
     * Find records from call history
     * @param data
     * @returns {Promise<*>}
     */
    findCallsHistory: async (data) => {
      const resource = `/cdrs/search/${data.call_from}`
      return await instance.get(resource)
    },

    /**
     * Save call to history
     * @param data
     * @returns {Promise<*>}
     */
    saveCallHistory: async (data) => {
      const resource = '/cdrs'
      return await instance.post(resource, data)
    },

    /**
 * Save call to history
 * @param params
 * @returns {Promise<{totalPages, data}>}
 */
    getCallHistoryWithPagination: async (params) => {
      const resource = '/cdrs/paginated'
      return await instance.get(resource, { params })
    }

  }
}
