
export default function i3LoggerApiModule (instance) {
  return {
    async callStartLogEvent (data) {
      const resource = 'i3-logger/call-start-log-event'
      try {
        setTimeout(async () => {
          await instance.post(resource, data)
        }, 5000)
      } catch (error) {
        console.log('Error while saving log', error)
      }
    },
    async callEndLogEvent (data) {
      const resource = 'i3-logger/call-end-log-event'
      try {
        setTimeout(async () => {
          await instance.post(resource, data)
        }, 5000)
      } catch (error) {
        console.log('Error while saving log', error)
      }
    },
    async locationQueryLogEvent (data) {
      const resource = 'i3-logger/location-query-log-event'
      try {
        setTimeout(async () => {
          await instance.post(resource, data)
        }, 5000)
      } catch (error) {
        console.log('Error while saving log', error)
      }
    },
    async locationResponseLogEvent (data) {
      const resource = 'i3-logger/location-response-log-event'
      try {
        setTimeout(async () => {
          await instance.post(resource, data)
        }, 5000)
      } catch (error) {
        console.log('Error while saving log', error)
      }
    },
    async additionalDataQueryLogEvent (data) {
      const resource = 'i3-logger/additional-data-query-log-event'
      try {
        setTimeout(async () => {
          await instance.post(resource, data)
        }, 5000)
      } catch (error) {
        console.log('Error while saving log', error)
      }
    },
    async additionalDataResponseLogEvent (data) {
      const resource = 'i3-logger/additional-data-response-log-event'
      try {
        setTimeout(async () => {
          await instance.post(resource, data)
        }, 5000)
      } catch (error) {
        console.log('Error while saving log', error)
      }
    }
  }
}
