export default class EmergencyCallDataParser {
  locationResponse = [];
  adrResponse = {}
  parseGeoLocation (Geolocation = []) {
    Geolocation.forEach(item => {
      const urlRegex = /(https?:\/\/[^\s>]+)/
      const match = item.raw.match(urlRegex)

      if (match) {
        this.locationResponse.location_url = this.clearUrl(match[0])
      }
    })

    return this.locationResponse
  }

  parseAdr (callInfo) {
    if (!callInfo) return this.adrResponse
    callInfo.forEach(item => {
      if (item.raw.endsWith('"EmergencyCallData.Comment"')) {
        this.adrResponse.comment_url = this.clearUrl(item.raw)
      }
      if (item.raw.endsWith('"EmergencyCallData.SubscriberInfo"')) {
        this.adrResponse.subscriber_info_url = this.clearUrl(item.raw)
      }
      if (item.raw.endsWith('"EmergencyCallData.ServiceInfo"')) {
        this.adrResponse.service_info_url = this.clearUrl(item.raw)
      }
      if (item.raw.endsWith('"EmergencyCallData.DeviceInfo"')) {
        this.adrResponse.device_info_url = this.clearUrl(item.raw)
      }
      if (item.raw.endsWith('"EmergencyCallData.ProviderInfo"')) {
        this.adrResponse.provider_info_url = this.clearUrl(item.raw)
      }
    })

    return this.adrResponse
  }

  clearUrl (data) {
    const urlPattern = /https?:\/\/[^\s<>,]+/
    const match = data.match(urlPattern)

    if (match) {
      return match[0]
    } else {
      return null
    }
  }
}
