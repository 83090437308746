import apiService from '@/modules/api/csp'

import CallHistoryTransformer from '@/modules/api/csp/transformers/call-history.transformer'

export const fetchCallHistoryAction = async ({ commit }, params) => {
  try {
    commit('setLoading', true)
    const { data } = await apiService.callHistory.getCallHistoryWithPagination(params)
    commit('setCallHistory', data)
  } catch (error) {
    console.error('Error fetching call history: ', error)
  } finally {
    commit('setLoading', false)
  }
}

export const saveCallHistoryAction = async ({ commit }, callData) => {
  const collectedAfterCallFormattedData = await new CallHistoryTransformer(callData).collectData()
  if (collectedAfterCallFormattedData) {
    await apiService.callHistory.saveCallHistory(collectedAfterCallFormattedData).then(async () => {
      commit('prependCallToHistory', { ...collectedAfterCallFormattedData, created_at: new Date() })
    }).catch((error) => {
      console.error('Error during recording to call history!: ', error)
    })
  }
}
